<template>
  <verify-account
    v-if="me.verification_id === null"
    :reverification="me.prev_verification_id"
    data-verifid="{me.verification_id}"
    context="proceed! Please verify in order to make your purchase." />

  <div
    v-else-if="!me.is_frozen"
    class="flex flex-col justify-center items-center min-h-[100%]">
    <h1 class="text-3xl font-bold mb-8">Pay with Credit Card</h1>

    <div v-if="paymentSuccessful" class="payment-success">
      <div class="success-text">Payment was successful</div>
      <img
        src="../../assets/paymentmethods/payment_done.svg"
        alt="Payment Success"
        class="success-image" />
      <button
        @click="loadHome"
        class="btn-bet rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4">
        DONE
      </button>
    </div>

    <div v-if="paymentError.length > 0" class="payment-success">
      <div class="success-text">Payment not successful</div>
      <img
        src="../../assets/paymentmethods/payment_error.svg"
        alt="Payment Error"
        class="success-image" />
      <h3 class="text-3xl font-bold mb-8">{{ paymentError }}</h3>
      <button
        @click="back"
        class="btn-bet rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4">
        GO BACK
      </button>
    </div>

    <form
      v-if="paymentError.length == 0 && !paymentSuccessful"
      class="w-96 max-w-full bg-black bg-opacity-25 rounded-lg shadow-lg px-8 py-8">
      <div>
        <!--        <div class="mb-4">-->
        <!--          <label for="first-name" class="block text-white font-medium">First Name</label>-->
        <!--          <input v-model="formData.firstName" pattern="[A-Za-z]+" title="Please enter a valid first name" type="text"-->
        <!--                 id="first-name" name="first-name" required-->
        <!--                 class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">-->
        <!--        </div>-->
        <div>
          <input v-model="formData.amount" id="amount" type="hidden" required />
          <input v-model="formData.currency" id="currency" type="hidden" required />
        </div>
        <!--        <div class="mb-4">-->
        <!--          <label for="last-name" class="block text-white font-medium">Last Name</label>-->
        <!--          <input v-model="formData.lastName" pattern="[A-Za-z]+" title="Please enter a valid last name" type="text"-->
        <!--                 id="last-name" name="last-name" required-->
        <!--                 class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <label for="email" class="block text-white font-medium">Email</label>-->
        <!--          <input v-model="formData.email" type="email" id="email" name="email" required-->
        <!--                 class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">-->
        <!--        </div>-->
        <!--        <div class="mb-4">-->
        <!--          <label for="phone" class="block text-white font-medium">Phone</label>-->
        <!--          <input v-model="formData.phone" required type="tel" id="phone" name="phone"-->
        <!--                 title="Please enter phone number without any country code"-->
        <!--                 minlength="10" maxlength="15" pattern="[0-9]+"-->
        <!--                 class="mt-1 px-4 py-2 w-full text-black rounded-md border-gray-300 focus:ring focus:ring-blue-300 focus:border-blue-300">-->
        <!--        </div>-->
        <div class="flex justify-center mt-6">
          <button
            type="submit"
            class="btn-bet rounded rounded-2xl hover:opacity-75 px-4 py-3 mt-3 w-3/4"
            :disabled="link.length > 0">
            <span v-if="link.length == 0" class="">Please wait...</span>
            <span v-else>Pay with Credit Card</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style>
.payment-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success-image {
  width: 200px;
  height: 200px;
}

.success-text {
  font-size: 24px;
  margin-top: 16px;
  text-align: center;
}
</style>

<script>
import $store from '@/store';
import axios from '@/lib/myAxios';
import { balanceStore } from '@/store/pinia/balanceStore';
import VerifyAccount from '@/components/Bank/VerifyAccount.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'PaySafeIdentityVerificationForm',
  isLoading: false,
  components: {
    VerifyAccount,
  },
  computed: {
    ...mapGetters(['me']),
  },
  data() {
    return {
      //VITE_PAY_SAFE_SERVER_KEY
      API_KEY_MERCHANT_TEST: import.meta.env.VITE_PAY_SAFE_PUBLIC_KEY,
      options: [],
      link: '',
      timer: null,
      showForm: true,
      paymentData: null,
      paymentSuccessful: false,
      paymentError: '',
      selectedOption: { key: null, value: null },
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        amount: this.$route.query.bundle,
        promotionId: this.$route.query.promotionId,
        currency: 'USD',
      },
    };
  },
  created() {
    if (this.me.verification_id !== null && !this.me.is_frozen) {
      let script = document.createElement('script');
      script.onload = async () => {
        try
        {
          axios
            .get('/payments/paysafe?currency=USD&amount=' + this.formData.amount)
            .then((response) => {
              // JSON responses are automatically parsed.
              this.startTimer();
              this.paymentData = response.data;
              localStorage.setItem('result', null);
              if(import.meta.env.VITE_NODE_ENV == 'production') {
                this.$gtm.trackEvent({
                  event: 'add_payment_info',
                  value: this.$route.query.bundle,
                  email: this.me.email,
                });
              }

              this.checkout(this.paymentData);
            })
            .catch((e) => {
              if(e.data.error != undefined)
                this.paymentError = e.data.error;
              else
                this.paymentError = 'Something went wrong. Please try again';
            });
        } catch(error) {
          console.log("Some issue with payment");
        }
      };
      script.async = true;
      script.src = 'https://hosted.paysafe.com/checkout/v2/paysafe.checkout.min.js';
      document.head.appendChild(script);
    }
  },
  methods: {
    ...mapActions(['fetchUser']),
    back() {
      this.$router.replace({
        path: this.$route.path,
        query: { tab: 'bank', page: 'deposit', action: 'bundles' },
      });
    },
    loadHome() {
      this.$router.push('/');
    },
    startTimer: function () {
      this.timer = setInterval(() => {
        var data = localStorage.getItem('result');
        if (data !== 'null') {
          clearInterval(this.timer);
          if (data == 'true') {

            if(import.meta.env.VITE_NODE_ENV == 'production') {
              //  tracking an event on successful purchase
              this.$gtm.trackEvent({
                event: 'purchase',
                value: this.$route.query.bundle,
                email: this.me.email,
              });
            }


            if ($store.getters.me.total_purchases == 0 && import.meta.env.VITE_NODE_ENV == 'production') {
              this.$gtm.trackEvent({
                event: 'ftd',
                value: this.$route.query.bundle,
                email: this.me.email,
              });
            }

            $store.dispatch('updateUserPurchases');

            this.paymentSuccessful = true;
            const balS = balanceStore();
            balS.init();
            this.fetchUser();
          } else {
            this.paymentError = data;
          }
        }
      }, 1000);
    },
    async checkout(data) {
      var promotionId = this.formData.promotionId;
      paysafe.checkout.setup(
        this.API_KEY_MERCHANT_TEST,
        {
          currency: data.currency, // 4 currencies currently supported by Paysafe Checkout - "USD", "CAD", "EUR", "GBP"
          amount: data.amount * 100, // This qualifies as USD $ 50.00. Multiply Payment Amount by 100 and supply here.
          payout: false, // payout: true --> for Withdrawal (standalone credits); payout: false --> for Payments/Deposit
          payoutConfig: {
            // payoutConfig section required only when payout: true [for Withdrawal cases].
            maximumAmount: 100000000,
          },
          locale: 'en_US',
          simulator: 'EXTERNAL',
          environment: import.meta.env.VITE_NODE_ENV === 'production' ? 'LIVE' : 'TEST', // environment: "LIVE" --> Production, "TEST" --> test environment for customers to try out Checkout & its features
          singleUseCustomerToken: data.singleUseCustomerToken,
          customer: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phone: data.phone,
          },
          merchantRefNum: data.merchantRefNum, // Will be unique and must keep changing every transaction
          canEditAmount: false, // Makes the payment amount editable on Checkout screen. Make it false to open Checkout with a fixed non-editable amount.
          merchantDescriptor: {
            dynamicDescriptor: 'Chanced Gold Coins',
            phone: '3126120233',
          },
          threeDs: {
            merchantUrl: 'https://api.qa.paysafe.com/checkout/v2/index.html#/desktop',
            deviceChannel: 'BROWSER',
            messageCategory: 'PAYMENT',
            authenticationPurpose: 'PAYMENT_TRANSACTION',
          },
          displayPaymentMethods: ['card', ''],
          // displayPaymentMethods : Array serves two purposes. You can use it to restrict the payment methods that a customer can see.
          // You can also use it to order the payment methods based on your preference.
          // If this field is present, the customer will only see those payment methods in the order specified, hence,
          // Ensure that you provide all payment methods if you are using it only for the purpose of payment method ordering.
          paymentMethodDetails: {
            // Please read the Checkout Objects Documentation on developer.paysafe.com .....
            card: {
              accountId: data.card_account_id, // Supply Account ID only if multiple accounts are configured with same payment method,
            },
          },
        },
        function (instance, error, result) {
          if (result != null && result.paymentHandleToken) {
            // pass this token to server to make the payment or payout using Paysafe Payments API.
            //finalise transaction
            try {
              axios
                .get(
                  '/payments/paysafe/finalise?merchantRefNum=' +
                  data.merchantRefNum +
                  '&currencyCode=' +
                  data.currency +
                  '&amount=' +
                  data.amount +
                  '&paymentHandleToken=' +
                  result.paymentHandleToken +
                  '&promotionId=' +
                  promotionId,
                )
                .then((response) => {
                  // JSON responses are automatically parsed.
                  if (response.data.status === 'COMPLETED') {
                    localStorage.setItem('result', 'true');
                    if (typeof instance.close !== "undefined")
                      instance.close();
                    // DISABLING AS ITS CALL IN ABOVE IN TIMER EVENT
                    /* if(import.meta.env.VITE_NODE_ENV == 'production')
                    {
                          this.$gtm.trackEvent({
                            event: 'purchase',
                            value: this.$route.query.bundle,
                            email: this.me.email,
                          });
                    } */

                    //fbq('track', 'Purchase', { value: data.amount, currency: 'USD' });
                  } else {
                    localStorage.setItem(
                      'result',
                      'Payment status is ' + response.data.status,
                    );
                    if (typeof instance.close !== "undefined")
                      instance.close();
                  }
                })
                .catch((e) => {
                  if (typeof instance.close !== "undefined")
                    instance.close();
                  localStorage.setItem('result', e.data.message);
                  //      window.location.href = import.meta.env.VITE_FRONTEND_URL
                });
            } catch (error) {
              localStorage.setItem('result', e.data.message);
              alert(" They are some issue while finilize payment please contact support");
            }

          } else {
            if (result) {
              var searchParams = new URLSearchParams();
              localStorage.setItem('result', error.displayMessage);
              if (typeof instance.close !== "undefined")
                instance.close();
            } else {
              var searchParams = new URLSearchParams();
              searchParams.append('data', error);
              localStorage.setItem('result', error.displayMessage);
              if (typeof instance.close !== "undefined")
                instance.close();
            }
          }
        },
        function(stage, expired) {
          switch (stage) {
            case 'PAYMENT_HANDLE_NOT_CREATED': // Handle the scenario probably cancelled
              localStorage.setItem('result', 'Payment cancelled');
            //  window.location.href = import.meta.env.VITE_FRONTEND_URL
            case 'PAYMENT_HANDLE_CREATED': // Handle the scenario
              console.log('PAYMENT_HANDLE_CREATED');
            case 'PAYMENT_HANDLE_REDIRECT': // Handle the scenario
              console.log('PAYMENT_HANDLE_REDIRECT');
            case 'PAYMENT_HANDLE_PAYABLE': // Handle the scenario
              console.log('PAYMENT_HANDLE_PAYABLE');
            default: // Handle the scenario
              console.log('Default');
          }
        },
      );
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
