<template>
  <landing v-if="!this.isLoggedIn && this.landingpage" />
  <div
      v-else
      class="h-auto max-w-[100%] md:min-h-[auto] flex flex-col p-0 md:p-4 items-center child:max-w-[1200px]">
    <div class="grid grid-cols-1 py-4 w-full max-[480px]:p-0">
      <home-slider />
      <bet-wins />
      <auth v-if="false" />
      <home-category-slider
          v-for="(cat, i) in getHomeCategories"
          :key="i"
          :category="cat"
      />
      <QuickBuy />
      <GameHistoryTable />
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Home/Auth.vue';
import Landing from '@/components/Home/Landing.vue';
import BetWins from '@/components/Home/BetWins.vue';
import QuickBuy from '@/components/Home/QuickBuy.vue';
import GameHistoryTable from '../GameHistory/BetsHistory.vue';

import { mapGetters } from 'vuex';
import HomeSlider from '@/components/Home/Slider/HomeSlider.vue';
import HomeCategorySlider from "@/components/Home/HomeCategorySlider.vue";

export default {
  name: 'Home',
  components: {
    HomeCategorySlider,
    HomeSlider,
    QuickBuy,
    Auth,
    Landing,
    GameHistoryTable,
    BetWins
  },
  computed: {
    ...mapGetters(['activeRoom', 'me', 'isLoggedIn', 'landingpage', 'categoryOptions']),
    getHomeCategories() {
      return this.categoryOptions.filter((category) => {
        return category.show_in_home;
      })
    }
  },
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    '$route.query.aid': {
      handler: function (newState) {
        if (this.$route.query.aid) {
          window.localStorage['aid'] = this.$route.query.aid;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
