<template>
    <div  class="page-wrapper py-6 px-3 sm:py-10 sm:px-6 lg:px-8 max-w-[1200px] mx-auto">
        <div class="head text-center mb-8">
            <h2 class="text-sm">Welcome to the</h2>
            <h1 class="text-2xl mb-2">Hall of Fame</h1>
            <p class="max-w-[600px] text-xs mx-auto">Here's a bunch of lucky customers who have been spinning and winning big with Paris at Chanced. You'll also find some handy info about the games on which the biggest wins were made!</p>
        </div>
        <div class="content">
            <div class="cards grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4" >
                <div class="card rounded-md bg-secondary-light p-2" v-for="(bet, index) in cards" :key="bet.id" >
                    <div class="top flex flex-row gap-3 mb-2">
                        <div class="img-wrapper">
                            <img
                              :src="getImageUrl(bet)"
                              @error="loadFallbackImage"
                              :data-fallback="getDefaultTileImage(bet)"
                              alt="Game Icon"
                              class="w-20 rounded-md object-cover">
                        </div>
                        <div class="flex flex-1 flex-col gap-1 justify-center">
                            <h3 class="text-[12px] font-extrabold uppercase sm:text-[16x]">{{(bet.user!=undefined )?bet.user.username:'Private user'}}</h3>
                            <div class="flex flex-row items-center gap-x-2">
                                <img alt="icon" :src="getIcon(bet.game_mode)" class="h-4 w-4" />
                                <p class="text-sm font-semibold text-lime-600">{{bet.profit}}</p>
                            </div>
                            <router-link
                            class="btn-bet bg-accent text-white shadow-btn rounded rounded-md hover:opacity-75 px-3 py-2 text-xs m-0"
                            :to="getLink(bet)">
                            Play Now
                            </router-link>
                        </div>
                    </div>
                    <div class="bottom">
                        <p class="text-[10px] mb-2 text-white/70">User {{(bet.user!=undefined )?bet.user.username:'Private user'}} won a massive <strong>{{bet.profit}}</strong> playing on <strong>{{bet.game_name}}</strong> from Supplier <strong>{{bet.game_mode}}</strong>.</p>
                        <!--<p class="text-[10px]  text-white/70"> </p>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { getBalanceIcon } from '@/lib/icons';
import axios from '@/lib/myAxios';
import { getDefaultTileImage, loadFallbackImage } from '@/utils';
export default {
  name: 'HallOfFame',
  data() {

    return {
            cards:  [  ] 
    };
    
  },
  async   mounted()
    {

        try 
        {
             const data = await this.getBigWins();
        
            this.cards = data.data;
        }catch(e)
        {
            console.log(e);
        }
       
    },
  methods: {
    getDefaultTileImage,
    loadFallbackImage,
    getIcon(currency) {
        return getBalanceIcon(currency);
    },
    async getBigWins()
    {
        return  await axios.get('bigwins');
    },

    getImageUrl(bet) {
        return `${location.protocol}//${location.host}/images/${bet.image}`;
    },
    getIdentifierName(gameName) {
        console.log(gameName);
        return ( gameName!=undefined && gameName.split(':')[1])?gameName.split(':')[1]:'';
    },
    getLink(game) {
        if (game.provider_slug === 'runebet') {
            /*const original = originals.find((o) => o.title === game.title);
            if (original) {
                return original.link;
            }*/
        }
        if (game.software == 'relax') {
        // const gameName = this.getIdentifierName(game.identifier);
        return `/casino/games/${game.provider_slug}-${game.identifier}`;
        }

        if (game.software == 'softswiss') {
        const gameName = this.getIdentifierName(game.identifier);
        
        
        return `/softswiss/casino/games/${game.provider_slug}-${gameName}`;
        }
        if (game.software == 'pragmatic') {
        return `/pragmatic/casino/games/${game.identifier}`;
        }
    },

    
  },
}
</script>
