<template>
  <giveaway-create
    v-if="tab === 'create' && me.is_streamer"
    :onSuccess="giveawaycreated" />

  <div class="flex w-full flex-col items-center">
    <div class="flex w-full max-w-[1200px] flex-col">
      <div
        class="relative justify-between flex w-full items-center justify-left align-center gap-2 bg-secondary/30 px-4 py-4 md:px-6 md:pb-0">
        <div class="flex flex-wrap items-center md:gap-5 gap-x-3 gap-y-0">
          <h1 class="py-4 text-2xl font-bold text-slate-300">Giveaways</h1>
          <router-link
            v-if="me.is_streamer"
            class="bg-accent text-white shadow-btn md:rounded-2xl hover:opacity-75 md:px-4 md:py-3 max-[767px]:text-sm px-2 py-2 rounded-lg"
            :to="'?tab=create'"
            >Create New</router-link
          >
        </div>
        <img
          class="h-[50px] lg:h-[120px] brightness-90 sm:h-[80px]"
          src="/src/assets/sidebar/giveaway.webp"
          alt="" />
      </div>
      <div
        v-if="false"
        class="relative flex items-center justify-end w-full gap-2 px-4 py-4 text-slate-300 md:px-6">
        <div class="flex flex-row items-center gap-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden w-5 h-5 rotate-90 md:block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          <span class="hidden text-sm md:block"> Sort By </span>
        </div>
      </div>
      <div class="px-4 py-2 md:px-6 md:py-4 md:pt-0">
        <div class="flex h-5 items-center justify-center" v-if="loading">
          <svg
            class="h-10"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3" />
            </circle>
          </svg>
        </div>
        <div class="flex w-full">
          <div class="flex w-full flex-row items-start md:py-2 sm:px-0 py-0">
            <perfect-scrollbar
              ref="hScrollbar"
              class="no-scrollbar flex overflow-x-scroll rounded-full bg-secondary">
              <div
                class="w-fsull ovesrflow-x-scroll grid w-full auto-cols-max grid-flow-col grid-cols-none grid-rows-1 flex-nowrap gap-0.5 sm:gap-3 p-2">
                <router-link
                  class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
                  :class="[
                    type === 'active'
                      ? 'bg-accent shadow-btn'
                      : 'text-white hover:bg-secondary-light/70 hover:text-white',
                  ]"
                  :to="'/giveaways/active'">
                  <span class="flex w-full items-center justify-center gap-2">
                    Active
                  </span>
                </router-link>
                <router-link
                  class="flex h-10 w-full min-w-fit items-center justify-center space-x-2 rounded-full px-4 py-2.5 text-sm font-medium leading-5 text-white focus:outline-none"
                  :class="[
                    type === 'finished'
                      ? 'bg-accent shadow-btn'
                      : 'text-white hover:bg-secondary-light/70 hover:text-white',
                  ]"
                  :to="'/giveaways/finished'">
                  <span class="flex w-full items-center justify-center gap-2">
                    Finished
                  </span>
                </router-link>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
        <div
          class="grid-rows-auto mt-4 grid w-full gap-3 md:gap-4 grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
          <div
            class="flex flex-col rounded-md relative shadow-md transition delay-0 duration-150 ease-in-out hover:rotate-1 hover:scale-105 overflow-hidden"
            v-for="(item, i) in items"
            :key="i"
            :class="
              item.subscribed == 1 || item.completed_at ? 'disabled opacity-20' : ''
            "
            :ref="'giveaway-ref-' + item.id">
            <div class="bg-accent text-2xl giveaway-id max-[767px]:text-sm">
              #{{ item.id }}
            </div>
            <router-link
              v-if="item.user_id == me.id"
              :to="getLink(item)"
              class="md:px-5 bg-accent absolute right-0 max-[767px]:text-sm px-3">
              manage
            </router-link>

            <img
              :src="getImageUrl(item)"
              @error="loadFallbackImage"
              :data-fallback="getDefaultTileImage()"
              alt="Game Tile"
              class="flex flex-col items-center w-full" />

            <div class="description p-4 bg-secondary">
              <p class="text-white font-bold max-[767px]:text-sm">
                {{ item.username }} is currently doing a {{ item.sc_amount }} SC +
                {{ item.gc_amount }} GC Giveaway.
              </p>
              <p class="text-[#2da72d] font-bold mt-1 max-[767px]:text-sm">
                Winners: {{ item.number_of_winner }}
              </p>

              <button
                v-if="item.subscribed == 0 && item.completed_at == null"
                class="bg-accent text-white shadow-btn rounded-md md:rounded-2xl hover:opacity-75 md:px-4 md:py-3 md:mt-3 px-3 py-2 mt-2 max-[767px]:text-sm"
                @click="joinTheuser(item, i)">
                Join now
              </button>
              <button
                v-else-if="item.subscribed > 0"
                class="bg-accent text-white shadow-btn rounded-md md:rounded-2xl hover:opacity-75 md:px-4 md:py-3 md:mt-3 px-3 py-2 mt-2 max-[767px]:text-sm">
                Joined
              </button>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col items-center justify-center py-12 text-slate-300/70"
          v-if="items.length === 0 && !loading">
          <svg width="96" height="96" fill="none" class="mx-auto mb-6 text-slate-300/70">
            <path
              d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <ellipse cx="37.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <ellipse cx="58.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <path
              d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
              fill="currentColor"></path>
          </svg>
          <span>Sorry! There are no Giveaways</span>
        </div>
        <div
          v-if="items.length > 0"
          class="mt-8 flex flex-col items-center justify-center">
          <span>Displaying {{ items.length }} of {{ total }} Giveaways</span>
          <span class="flex h-5 items-center justify-center" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </span>
          <button
            v-if="!loading && current_page < last_page"
            v-on:click="init(true)"
            :disabled="loading"
            class="bg-[#1EE563] text-secondary rounded-md md:rounded-2xl hover:opacity-75 md:px-4 md:py-3 md:mt-3 px-3 py-2 mt-2 max-[767px]:text-sm">
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GiveawayStores } from '@/store/pinia/giveawayStore';

import GiveawayCreate from '@/components/Giveaways/GiveawayCreate.vue';
import sc from '../../assets/coins/cash.png';
import gc from '../../assets/coins/gold.png';
import originals from '@/components/Relax/originals';
import { getDefaultTileImage, loadFallbackImage } from '@/utils';

export default {
  name: 'Giveaways',
  components: {
    GiveawayCreate,
  },
  props: {
    type: {
      default: null,
    },
  },
  setup() {
    return { giveawayStore: GiveawayStores() };
  },
  computed: {
    ...mapGetters(['me', 'isLoggedIn']),
  },

  async created() {
    this.init();
  },
  data() {
    return {
      isCreate: false,
      loading: false,
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,

      providers: [],
      sortOrder: '',
      sortType: 'desc',
    };
  },
  methods: {
    getDefaultTileImage,
    loadFallbackImage,
    getImageUrl(item) {
      return `${location.protocol}//${
        location.host
      }/images/giveaways/${item.username.toLowerCase()}.webp`;
    },
    giveawaycreated() {
      this.init();
    },
    async init(loadMore = false) {
      try {
        let page = loadMore ? this.current_page + 1 : 1;
        const { data } = await this.giveawayStore.fetchAll({
          type: this.type,
          sort: this.sortOrder,
          sorttype: this.sortType,
          page,
        });

        const items = loadMore ? this.items : [];
        this.items = [...items, ...data.results.data];

        this.current_page = data.results.current_page;
        this.last_page = data.results.last_page;
        this.per_page = data.results.per_page;
        this.total = data.results.total;
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },

    getLink(item) {
      return this.me.id == item.user_id ? `/giveaways/${item.id}` : false;
    },

    getcoinimage(coin) {
      return coin == 'SC' ? sc : gc;
    },

    changeSortOrder(order) {
      if (this.sortOrder == order) {
        if (this.sortType == 'desc') this.sortType = 'asc';
        else this.sortType = 'desc';
      }

      this.sortOrder = order;
    },
    opencreate() {
      this.isCreate = true;
    },
    async joinTheuser(item, key) {
      if (!this.loading) {
        this.loading = true;
        try {
          const data = await this.giveawayStore.joinGiveaway(item.id);

          if (data.data.success == true) {
            this.$toast.success(data.data.message);
            if (this.items[key]) this.items[key].subscribed = 1;
          } else {
            this.$toast.error('Slow down! You’re moving too fast. Please try again.');
          }
        } catch (e) {
          if (typeof e.data.error != 'undefined') this.$toast.error(e.data.error);
          else this.$toast.error('Slow down! You’re moving too fast. Please try again.');
        }

        this.loading = false;
      }
    },
    async joinbyid(id) {
      if (!this.loading) {
        this.loading = true;
        try {
          const data = await this.giveawayStore.joinGiveaway(id);

          if (data.data.success == true) {
            this.$toast.success(data.data.message);
            this.init();
          } else {
            this.$toast.error('Slow down! You’re moving too fast. Please try again.');
          }
        } catch (e) {
          if (typeof e.data.error != 'undefined') this.$toast.error(e.data.error);
          else this.$toast.error('Slow down! You’re moving too fast. Please try again.');
        }

        this.loading = false;
      }
    },
  },
  watch: {
    '$route.query.tab': {
      handler: function (newState) {
        this.tab = newState;
      },
      deep: true,
      immediate: true,
    },
    '$route.query.join': {
      handler: function (newState) {
        this.join = newState;
        if (this.join) this.joinbyid(this.join);
      },
      deep: true,
      immediate: true,
    },
    type() {
      this.init();
    },
  },
};
</script>
