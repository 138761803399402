import { defineStore } from 'pinia';
import axios from '@/lib/myAxios';

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const relaxStore = defineStore('relax', {
  state: () => {
    return {
      selected: {
        name: null,
        icon: null,
      },
      featured: null,
    };
  },
  getters: {},
  actions: {
    async createGameSession(isMobile, gameId, fiat, currencyType, balance) {
      const channel = isMobile ? 'mobile' : 'web';
      const url = '/games/relax/session';
      const gameMode = 'real';
      return await axios.post(url, {
        channel,
        gameId,
        fiat,
        gameMode,
        currencyType,
        balance,
      });
    },
    // async createSession(currency, amount, address, tag) {
    //   return await axios.post("/games/relax/session", {
    //     currency,
    //     amount,
    //     address,
    //     tag,
    //   });
    // },
    async fetchFeatured() {
      if (!this.featured) {
        const { data } = await axios.get('relax/featured');
        this.featured = data;
      }
      return this.featured;
    },
    async fetchAll(params) {
      return await axios.get('all/games', { params });
    },
    async fetchGame(identifier) {
      return await axios.get('relax/games/' + identifier);
    },
    async toggleFavourite(data) {
      return await axios.post('favourite', data);
    },
    async checkFavourite(data) {
      return await axios.post('favourite/check', data);
    },
  },
});
