import { sampleSize } from 'lodash';
import { defaultConvertor } from './lib/rsgp';

const ALPHA_NUM = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

function* clientSeedGenerator(alpha, length) {
  while (true) {
    yield sampleSize(alpha, length).join('');
  }
}

export function clientSeedGeneratorFactory(
  opts = {
    alpha: ALPHA_NUM,
    length: 12,
  },
) {
  const { length, alpha } = opts;

  return clientSeedGenerator(alpha, length);
}

export function formatRsgp(amount) {
  return defaultConvertor.format(amount);
}
export function formatBlockChainUrl(coin, address) {
  // if (typeof url !== 'undefined' || url===null) {
  //    return "https://blockchair.com/bitcoin/transaction/";
  // }
  // return "https://blockchair.com/bitcoin/transaction/"+url;

  let currency = coin.toUpperCase();
  switch (currency) {
    case 'DOGE':
      var path = 'https://blockchair.com/dogecoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'ETH':
      var path = 'https://blockchair.com/ethereum/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BTC':
      var path = 'https://blockchair.com/bitcoin/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'XRP':
      var path = 'https://blockchair.com/ripple/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'TRX':
      var path = 'https://tronscan.org/#/blockchain/transactions/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'EOS':
      var path = 'https://bscscan.com/token/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
    case 'BCH':
      var path = 'https://blockchair.com/bitcoin-cash/transaction/' + address;
      return '<a href="' + path + '" target="_blank">View Transaction</a>';
      break;
  }
}
export default {
  clientSeedGeneratorFactory,
};
export function checkIsSet(variable) {
  if (typeof variable !== 'undefined' || variable === null) {
    return false;
  }
  return true;
}

export function loadFallbackImage(event) {
  if (event.target.src !== event.target.getAttribute('data-fallback'))
    event.target.src = event.target.getAttribute('data-fallback');
  else return false;
}

export function getDefaultCategoryIcon() {
  return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/categories/default.webp`;
}

export function getDefaultTileImage(game = null) {
  if (game?.software === 'softswiss') {
    const identifier = game.identifier.split(':')[1];

    return `https://cdn.softswiss.net/i/s4/${game.provider}/${identifier}.png`;
  }

  return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/images/default.webp`;
}

export function getCategoryIcon(category) {
  return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/${category.icon}`;
}

export function getTileImage(game) {
  let identifier = game.identifier;
  if (game?.software === 'softswiss') {
    identifier = game.identifier.split(':')[1];
  }

  if (game?.provider === 'runebet') {
    identifier = game.identifier.replace('original-', '');
    identifier = identifier.replace(/-/g, '');
  }

  return `${import.meta.env.VITE_AWS_CF_ASSET_URL}/images/${game.software}/${identifier}.webp`;
}

export function getLink(game) {
  if (game.provider === 'runebet') {
    let gameName = game.identifier.replace('original-', '');
    gameName = gameName.replace(/-/g, '');

    return `/casino/${gameName}`;
  }

  if (game.software == 'relax') {
    return `/casino/games/${game.provider}-${game.identifier}`;
  }

  if (game.software == 'softswiss') {
    const gameName = game.identifier.split(':')[1];
    return `/softswiss/casino/games/${game.provider}-${gameName}`;
  }

  if (game.software == 'pragmatic') {
    return `/pragmatic/casino/games/${game.identifier}`;
  }
}