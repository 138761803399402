<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex w-full max-w-[1200px] flex-col">
      <div
        class="relative flex items-center justify-between w-full gap-2 px-4 py-4 bg-secondary/30 md:px-6">
        <h1 class="py-4 text-2xl font-bold text-slate-300">
          {{ this.group.name }}
        </h1>
        <img
          class="absolute right-5 h-[120px] brightness-90"
          :src="this.group.icon"
          alt="" />
      </div>
      <div
        class="relative flex items-center justify-between w-full gap-2 px-4 py-4 text-slate-300 md:px-6">
        <div class="flex flex-row items-center gap-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden w-5 h-5 rotate-90 md:block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          <span class="hidden text-sm md:block"> Filter By </span>
          <provider-filters
            :providers="providers"
            :toggle-provider="toggleProvider"
            :clear-all="clearProviders"
            :selected-providers="selectedProviders" />
        </div>
        <div class="flex flex-row items-center gap-1.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="hidden w-5 h-5 rotate-90 md:block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2">
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
          </svg>
          <span class="hidden text-sm md:block"> Sort By </span>
          <sort-filters :sort="sortOrder" :change-sort-order="changeSortOrder" />
        </div>
      </div>
      <div class="px-4 py-2 md:px-6 md:py-4">
        <div class="relative w-full mt-4 md:mt-0">
          <div
            class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                fill-rule="evenodd"></path>
            </svg>
          </div>
          <input
            v-model="searchText"
            class="input-search"
            placeholder="Search your game"
            type="text" />
        </div>
        <div class="flex flex-row justify-between mt-4"></div>
        <h-navigator />
        <div class="flex items-center justify-center h-5" v-if="loading">
          <svg
            class="h-10"
            id="L4"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enable-background="new 0 0 0 0"
            xml:space="preserve">
            <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.1" />
            </circle>
            <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.2" />
            </circle>
            <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
              <animate
                attributeName="opacity"
                dur="1s"
                values="0;1;0"
                repeatCount="indefinite"
                begin="0.3" />
            </circle>
          </svg>
        </div>

        <div class="grid w-full grid-cols-3 gap-6 mt-4 grid-rows-auto md:grid-cols-5">
          <router-link
            class="flex flex-col"
            v-for="(game, i) in games"
            :key="i"
            :to="getLink(game)">
            <img
              :src="getImageUrl(game)"
              alt="Game Tile"
              @error="loadFallbackImage"
              v-tooltip="game.gameName"
              :data-fallback="getOriginalImage(game)"
              class="flex flex-col items-center transition duration-150 ease-in-out rounded-md shadow-md w-28 delay-0 hover:rotate-1 hover:scale-105 md:w-56" />
            <span
              class="px-4 py-1 mt-1 font-semibold text-center text-md rounded-2xl text-slate-300"
              >{{ game.gameName }}</span
            >
          </router-link>
        </div>
        <div
          class="flex flex-col items-center justify-center py-12 text-slate-300/70"
          v-if="games.length === 0 && !loading">
          <svg width="96" height="96" fill="none" class="mx-auto mb-6 text-slate-300/70">
            <path
              d="M36 28.024A18.05 18.05 0 0025.022 39M59.999 28.024A18.05 18.05 0 0170.975 39"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <ellipse cx="37.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <ellipse cx="58.5" cy="43.5" rx="4.5" ry="7.5" fill="currentColor"></ellipse>
            <path
              d="M24.673 75.42a9.003 9.003 0 008.879 5.563m-8.88-5.562A8.973 8.973 0 0124 72c0-7.97 9-18 9-18s9 10.03 9 18a9 9 0 01-8.448 8.983m-8.88-5.562C16.919 68.817 12 58.983 12 48c0-19.882 16.118-36 36-36s36 16.118 36 36-16.118 36-36 36a35.877 35.877 0 01-14.448-3.017"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"></path>
            <path
              d="M41.997 71.75A14.94 14.94 0 0148 70.5c2.399 0 4.658.56 6.661 1.556a3 3 0 003.999-4.066 12 12 0 00-10.662-6.49 11.955 11.955 0 00-7.974 3.032c1.11 2.37 1.917 4.876 1.972 7.217z"
              fill="currentColor"></path>
          </svg>
          <span>Sorry! There are no results matching the selected criteria.</span>
        </div>
        <div
          v-if="games.length > 0"
          class="flex flex-col items-center justify-center mt-8">
          <span>Displaying {{ games.length }} of {{ total }} games</span>
          <span class="flex items-center justify-center h-5" v-if="loading">
            <svg
              class="h-10"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve">
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1" />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2" />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3" />
              </circle>
            </svg>
          </span>
          <button
            v-if="!loading && current_page < last_page"
            v-on:click="init(true)"
            :disabled="loading"
            class="mt-4 flex items-center justify-center rounded-xl bg-[#1EE563] p-3 font-semibold text-[#111F29] hover:opacity-70">
            Load More
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HNavigator from '../Home/HNavigator.vue';

import { pragmaticStore } from '@/store/pinia/pragmaticStore';
import { icons } from '../Shared/icons';
import { debounce } from 'lodash';
import ProviderFilters from '@/components/Relax/ProviderFilters.vue';
import SortFilters from '@/components/Relax/SortFilters.vue';

export default {
  name: 'PragmaticTiles',
  components: {
    ProviderFilters,
    SortFilters,
    HNavigator,
  },
  setup() {
    const groups = {
      all: {
        name: 'All Games',
        icon: '',
        slug: 'all',
        categories: [],
        sub_category: [],
        options: {},
      },
      slots: {
        name: 'Slot Games',
        icon: icons.Slots,
        categories: ['slots'],
        sub_category: [],
        slug: 'slots',
        options: {},
      },
      // "game-shows": {
      //   name: "Game Shows",
      //   icon: icons.GameShows,
      //   slug: "game-shows",
      //   categories: [],
      //   sub_category: ["game-show"],
      //   options: {},
      // },
      baccarat: {
        name: 'Baccarat',
        icon: icons.Baccarat,
        slug: 'baccarat',
        categories: [],
        sub_category: ['baccarat'],
        options: {},
      },
      originals: {
        name: 'Chanced Originals',
        slug: 'originals',
        icon: icons.Originals,
        categories: [],
        sub_category: [],
        options: { provider: 'runebet' },
      },
      recent: {
        name: 'Recent',
        slug: 'recent',
        icon: icons.Recent,
        categories: [],
        sub_category: [],
        options: { recent: 'recent' },
      },
      favorites: {
        name: 'Favorites',
        slug: 'favorites',
        icon: icons.Favourites,
        categories: [],
        sub_category: [],
        options: { favourites: 'favorites' },
      },
      'refer-a-friend': {
        name: 'Refer A Friend',
        slug: 'refer a friend',
        icon: icons.ReferFriend,
        categories: [],
        sub_category: [],
        options: {},
      },
      live: {
        name: 'Live Dealers',
        icon: icons.Live,
        slug: 'live',
        categories: [],
        sub_category: ['blackjack', 'roulette', 'baccarat', 'game_show'],
        options: { live: true },
      },
      blackjack: {
        name: 'Blackjack',
        icon: icons.Blackjack,
        slug: 'blackjack',
        categories: [],
        sub_category: ['blackjack'],
        options: {},
      },
      roulette: {
        name: 'Roulette',
        icon: icons.Roulette,
        slug: 'roulette',
        categories: [],
        sub_category: ['roulette'],
        options: {},
      },
      /*'new-releases': {
        name: 'New Releases', icon: icons.NewReleases, slug: 'slots', categories: ['cards', 'roulette', 'blackjack', 'slots']
      },*/
      'table-games': {
        name: 'Table Games',
        icon: icons.TableGames,
        slug: 'table-games',
        sub_category: ['blackjack', 'roulette', 'baccarat'],
        categories: [],
        options: {},
      },
      'scratch-cards': {
        name: 'Scratch Cards',
        icon: icons.Pvp,
        slug: 'scratch-cards',
        sub_category: ['scratch'],
        categories: [],
        options: {},
      },
      poker: {
        name: 'Poker',
        icon: icons.Poker,
        slug: 'poker',
        sub_category: [],
        categories: ['poker'],
        options: {},
      },
      'new-releases': {
        name: 'New Releases',
        icon: icons.NewReleases,
        slug: 'new-releases',
        sub_category: ['new'],
        categories: [],
        options: {},
      },
    };
    return { groups, pragmaticS: pragmaticStore() };
  },
  computed: {
    group() {
      return this.currentGroup in this.groups
        ? this.groups[this.currentGroup]
        : this.groups.all;
    },
    noResult() {
      return this.searchText.length > 0 && !this.loading && this.games.length === 0;
    },
  },
  created() {
    this.debouncedSearch = debounce(this.init, 300, {
      leading: false,
      trailing: true,
    });
  },
  data() {
    return {
      currentGroup: '',
      searchText: '',
      loading: false,
      games: [],
      providers: [],
      selectedProviders: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      debouncedSearch: null,
      sortOrder: 'featured',
    };
  },
  methods: {
    async init(loadMore = false) {
      try {
        this.loading = true;
        const group = this.groups[this.currentGroup];
        const category = group.categories.join(',');
        const sub_category = group.sub_category.join(',');

        const providers = 'pragmatic';

        let search = this.searchText.length >= 3 ? this.searchText : '';
        let page = loadMore ? this.current_page + 1 : 1;

        const { data } = await this.pragmaticS.fetchAll({
          sort: this.sortOrder,
          providers,
          category,
          sub_category,
          search,
          page,
          ...group.options,
        });
        const games = loadMore ? this.games : [];
        this.games = [...games, ...data];
        //this.providers = data.providers;
        /*this.current_page = data.results.current_page;
        this.last_page = data.results.last_page;
        this.per_page = data.results.per_page;
        this.total = data.results.total;*/
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    getImageUrl(game) {
      return `${location.protocol}//${location.host}/images/pragmatic/${game.gameID}.webp`;
    },
    getLink(game) {
      // const gameName = this.getIdentifierName(game.identifier);
      return `/pragmatic/casino/games/${game.gameID}`;
    },
    loadFallbackImage(event) {
      if (event.target.src != event.target.getAttribute('data-fallback'))
        event.target.src = event.target.getAttribute('data-fallback');
      else return false;
    },
    getOriginalImage(game) {
      return `${location.protocol}//${location.host}/images/default.webp`;
    },
    getIdentifierName(gameName) {
      return gameName.split(':')[1];
    },
    toggleProvider(provider) {
      const selected = this.selectedProviders.find((x) => x === provider.id);
      if (selected) {
        this.selectedProviders = this.selectedProviders.filter((x) => x !== provider.id);
      } else {
        this.selectedProviders.push(provider.id);
      }
      if (this.providers.length > 0) {
        this.init();
      }
    },
    clearProviders() {
      this.selectedProviders = [];
      if (this.providers.length > 0) {
        this.init();
      }
    },
    changeSortOrder(order) {
      if (this.sortOrder !== order) {
        this.sortOrder = order;
        this.init();
      }
    },
  },
  watch: {
    '$route.params.name': {
      handler: async function (newState) {
        this.currentGroup = newState;

        await this.init();
      },
      deep: true,
      immediate: true,
    },
    searchText() {
      this.debouncedSearch();
    },
  },
};
</script>
